









































import Vue from "vue";
import QueueCard from '@/components/cards/QueueCard.vue';
import {mapActions, mapGetters} from 'vuex';
import dayjs from 'dayjs';

export default Vue.extend({
  name: "CheckedOutAppointments",
  components: {QueueCard},
  data() {
    return {
      isModalOpen: false,
    }
  },
  watch: {
    isModalOpen() {
      if (this.checkedOutModalState !== this.isModalOpen) {
        this.$store.dispatch('TOGGLE_CHECKED_OUT_APPOINTMENTS_MODAL');
      }
    },
    checkedOutModalState() {
      if (this.checkedOutModalState !== this.isModalOpen) {
        this.$store.dispatch('GET_COMPLETED_APPOINTMENTS');
        this.isModalOpen = this.checkedOutModalState;
      }
    },
    completedAppointmentsSorted() {
      if (this.completedAppointmentsSorted.length > 0) {
      }
    }
  },
  computed: {
    ...mapGetters(['checkedOutModalState', 'completedAppointmentsSorted', 'loadingCompletedAppointments']),
    filteredQueue() {
      if (!this.filter) return this.queue;
      return this.completedAppointmentsSorted.filter(item => {
        const doctors = item.treatments.map(t => t.doctor.uuid);
        return doctors.includes(this.filter);
      });
    },
  },
  methods: {
    ...mapActions([
      'selectAppointment',
    ]),
    openAppointmentDialog(appointment) {
      this.selectAppointment({
        appointment: {...appointment, starts_at: dayjs(appointment.starts_at)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss') }
      })
    },
    getDoctorName(item) {
      return item.treatments?.[0]?.doctor?.name ?? item.doctor?.name ?? 'X X'
    },
  }
})
